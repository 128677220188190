import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  CircularProgress,
  Paper,
  Box,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import './App.css';

const Input = styled('input')({
  display: 'none',
});

export default function App() {
  const [videoFile, setVideoFile] = useState(null);
  const [audioReady, setAudioReady] = useState(false);
  const [transcription, setTranscription] = useState(null);
  const [rawTranscriptionData, setRawTranscriptionData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState({
    videoUpload: false,
    audioConversion: false,
    transcription: false,
    transcriptionKaldi: false,
  });

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
    setAudioReady(false);
    setTranscription(null);
    setRawTranscriptionData(null);
    setError(null);
  };

  const convertToAudio = async () => {
    if (!videoFile) {
      setError("Please upload a video first.");
      return;
    }
  
    setLoading((prev) => ({ ...prev, audioConversion: true }));
    setError(null);
  
    const formData = new FormData();
    formData.append('file', videoFile);
  
    try {
      const response = await fetch('http://52.76.236.100:5001/convert_internal', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Audio conversion failed');
      }
  
      const data = await response.json();
      setAudioReady(true);
      console.log(data);
      alert(`Video successfully converted to audio! Filename: ${data.audio_file}`);
    } catch (error) {
      console.error('Error:', error);
      setError('Error during audio conversion');
    }
  
    setLoading((prev) => ({ ...prev, audioConversion: false }));
  };

  const handleChatCutTranscription = async () => {
    if (!audioReady) {
      setError("Please convert the video to audio first.");
      return;
    }
  
    setLoading((prev) => ({ ...prev, transcription: true }));
    setError(null);
  
    try {
      const response = await fetch('http://52.76.236.100:5001/transcribe_internal', {
        method: 'POST',
      });
  
      if (!response.ok) {
        throw new Error('Transcription failed');
      }
  
      const data = await response.json();
      console.log("ChatCut Transcription Response:", data);
  
      setRawTranscriptionData(data);
  
      if (data.transcription && Array.isArray(data.transcription)) {
        setTranscription(data.transcription);
      } else {
        console.error("Invalid transcription data structure:", data);
        throw new Error('Invalid transcription data received');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error during transcription');
    }
  
    setLoading((prev) => ({ ...prev, transcription: false }));
  };

  const handleKaldiTranscription = async () => {
    if (!audioReady) {
      setError("Please convert the video to audio first.");
      return;
    }

    setLoading((prev) => ({ ...prev, transcriptionKaldi: true }));
    setError(null);

    try {
      const response = await fetch('http://52.76.236.100:5001/transcribe_kaldi', {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Kaldi transcription failed');
      }

      const data = await response.json();
      console.log("Kaldi Transcription Response:", data);

      setRawTranscriptionData(data);

      if (data.transcription && Array.isArray(data.transcription)) {
        setTranscription(data.transcription);
      } else {
        console.error("Invalid transcription data structure:", data);
        throw new Error('Invalid transcription data received');
      }
    } catch (error) {
      console.error('Error during Kaldi transcription:', error);
      setError('Error during transcription with Kaldi');
    }

    setLoading((prev) => ({ ...prev, transcriptionKaldi: false }));
  };

  const downloadRawTranscriptionJSON = () => {
    if (rawTranscriptionData) {
      const dataStr = JSON.stringify(rawTranscriptionData, null, 2);
      const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      const exportFileDefaultName = 'raw_transcription.json';

      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    }
  };
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Video Transcription App</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <label htmlFor="contained-button-file">
              <Input
                accept="video/mp4"
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                {videoFile ? videoFile.name : 'Select MP4 File'}
              </Button>
            </label>
            <Button
              variant="contained"
              onClick={convertToAudio}
              disabled={!videoFile || loading.audioConversion}
            >
              {loading.audioConversion ? 'Converting...' : 'Convert to Audio'}
            </Button>
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Box display="flex" justifyContent="space-around">
            <Button
              variant="contained"
              onClick={handleChatCutTranscription}
              disabled={!audioReady || loading.transcription}
            >
              {loading.transcription ? 'Transcribing with ChatCut...' : 'Transcribe with ChatCut'}
            </Button>
            <Button
              variant="contained"
              onClick={handleKaldiTranscription}
              disabled={!audioReady || loading.transcriptionKaldi}
            >
              {loading.transcriptionKaldi ? 'Transcribing with Kaldi...' : 'Transcribe with Kaldi'}
            </Button>
          </Box>
        </Paper>

        {(loading.audioConversion || loading.transcription || loading.transcriptionKaldi) && (
          <Box display="flex" justifyContent="center" alignItems="center" height={200}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
        )}

        {transcription && Array.isArray(transcription) && transcription.length > 0 && (
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>Transcription Result</Typography>
            <List>
              {transcription.map((segment, index) => (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={`Segment ${index + 1}`}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.primary">
                            {segment.segment.text}
                          </Typography>
                          <br />
                          <Typography component="span" variant="caption">
                            Speaker: {segment.segment.speaker} |
                            Time: {segment.segment.start.toFixed(2)}s - {segment.segment.end.toFixed(2)}s
                          </Typography>
                          <Box sx={{ mt: 1 }}>
                            {segment.words.map((word, wordIndex) => (
                              <Chip
                                key={wordIndex}
                                label={`${word.word} (${word.start.toFixed(2)}s-${word.end.toFixed(2)}s)`}
                                size="small"
                                sx={{ m: 0.5 }}
                                title={`Speaker: ${word.speaker}, Score: ${word.score.toFixed(2)}`}
                              />
                            ))}
                          </Box>
                        </>
                      }
                    />
                  </ListItem>
                  {index < transcription.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={downloadRawTranscriptionJSON}
                startIcon={<DownloadIcon />}
              >
                Download Raw JSON
              </Button>
            </Box>
          </Paper>
        )}
      </Container>
    </>
  );
}